import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "services/JwtAuthService";
import MenuService from "services/MenuService";

export const AppViews = () => {
	let intervalId = useRef(null);

	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}

		intervalId.current = setInterval(async () => {
			if (JwtAuthService.isLogged()) {
				MenuService.populateMenuData();
			}
		}, 30000);
	};

	useEffect(() => {
		populateMenu();

		return () => {
			clearInterval(intervalId.current);
		};
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/home`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/dashboard-testes`}
					component={lazy(() => import(`./dashboard-testes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/dados-conta`}
					component={lazy(() => import(`./dados-conta`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-usuarios`}
					component={lazy(() => import(`./lista-usuarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario/:id`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-empresas`}
					component={lazy(() => import(`./lista-empresas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/empresa/:id`}
					component={lazy(() => import(`./empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/empresa`}
					component={lazy(() => import(`./empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-produtos`}
					component={lazy(() => import(`./lista-produtos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/produto/:id`}
					component={lazy(() => import(`./produto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/produto`}
					component={lazy(() => import(`./produto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-testes-unitarios`}
					component={lazy(() => import(`./lista-testes-unitarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/teste-unitario/:id`}
					component={lazy(() => import(`./teste-unitario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/teste-unitario`}
					component={lazy(() => import(`./teste-unitario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-configuracoes-teste`}
					component={lazy(() =>
						import(`./lista-configuracoes-teste`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracao-teste/:id`}
					component={lazy(() => import(`./configuracao-teste`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracao-teste`}
					component={lazy(() => import(`./configuracao-teste`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-categorias-produto`}
					component={lazy(() => import(`./lista-categorias-produto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/categoria-produto/:id`}
					component={lazy(() => import(`./categoria-produto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/categoria-produto`}
					component={lazy(() => import(`./categoria-produto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-modelos-jiga`}
					component={lazy(() => import(`./lista-modelos-jiga`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/modelo-jiga/:id`}
					component={lazy(() => import(`./modelo-jiga`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/modelo-jiga`}
					component={lazy(() => import(`./modelo-jiga`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-unidades-empresa`}
					component={lazy(() => import(`./lista-unidades-empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/unidade-empresa/:id`}
					component={lazy(() => import(`./unidade-empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/unidade-empresa`}
					component={lazy(() => import(`./unidade-empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-jigas`}
					component={lazy(() => import(`./lista-jigas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/jiga/:id`}
					component={lazy(() => import(`./jiga`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/jiga`}
					component={lazy(() => import(`./jiga`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-logs-teste`}
					component={lazy(() => import(`./lista-logs-teste`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-log-teste/:id`}
					component={lazy(() => import(`./detalhes-log-teste`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/rodar-teste/:id`}
					component={lazy(() => import(`./rodar-teste`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/testes`}
					component={lazy(() => import(`./testes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-categoria-componente`}
					component={lazy(() =>
						import(`./lista-categoria-componente`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/categoria-componente/:id`}
					component={lazy(() => import(`./categoria-componente`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/categoria-componente`}
					component={lazy(() => import(`./categoria-componente`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-componente`}
					component={lazy(() => import(`./lista-componente`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/componente/:id`}
					component={lazy(() => import(`./componente`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/componente`}
					component={lazy(() => import(`./componente`))}
				/>
				<Redirect
					from={`${APP_PREFIX_PATH}`}
					to={`${APP_PREFIX_PATH}/home`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
